<template>
  <div :style="style" />
</template>

<script lang="ts" setup>
interface BaseSpriteAnimationProps {
  src: string
  frames: number
  framesPerSecond?: number
  width: number
  height: number
}

const props = withDefaults(defineProps<BaseSpriteAnimationProps>(), { framesPerSecond: 12 })

const style = computed(() => ({
  aspectRatio: `${props.width}/${props.height}`,
  backgroundImage: `url(${props.src})`,
  backgroundSize: 'auto 100%',
  '--sprite-steps': props.frames - 1,
  '--sprite-duration': `${(props.frames * 1000) / props.framesPerSecond}ms`,
}))
</script>

<style lang="postcss" scoped>
div {
  animation: sprite var(--sprite-duration) steps(var(--sprite-steps)) infinite;
}

@keyframes sprite {
  to {
    background-position-x: 100%;
  }
}
</style>
